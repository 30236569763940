<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require(`@/assets/img/integrations/form.svg`)" width="115"
           alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="integration.name"
            disabled
        >
          <template v-slot:label>
            Название интеграции
          </template>
        </v-text-field>
        <div class="item-page__actions mt-1">
          <v-btn text class="evi-button-text" @click="removeModal = true" v-if="hasRight('integrations.manage')">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <v-autocomplete
            class="evi-autocomplete"
            color="#44D370"
            v-model="selectedUsers"
            :items="localUsers"
            item-text="name"
            item-value="id"
            placeholder="Выберите ответственных сотрудников"
            multiple
            :loading="userLoading"
            @focus="loadUsers"
            :search-input.sync="userSearch"
            @input.native="searchUser(userSearch)"
            cache-items
            clearable
            :disabled="!hasRight('integrations.manage')"
            @change="saveUsers"
            item-color="green"
        >
          <template v-slot:label>
            Выберите ответственных сотрудников
          </template>
          <template v-slot:append-item>
            <div v-intersect="userIntersect"/>
          </template>
          <template v-slot:item="{item}">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <div class="v-list-item__title">{{ item.name }}</div>
          </template>
          <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-11"
            color="#44D370"
            :items="localTags"
            v-model="selectedTags"
            multiple
            item-text="name"
            item-value="id"
            @change="saveTags"
            :readonly="!hasRight('integrations.manage')"
            :clearable="hasRight('integrations.manage')"
            item-color="green"
            return-object
            @focus="getTags"
        >
          <template v-slot:label>
            Теги для клиентов
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="evi-autocomplete__tag mr-2 mt-2"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="evi-autocomplete__tag"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <div class="d-flex flex-column mt-5" v-if="integration.form_params">
          <h4 class="mb-11">Разместить форму на сайте:</h4>
          <p class="item-page__subdescription"><a class="text-decoration-underline" @click="copyScript">Скопируйте код</a>, разместите его в
            HTML-разметке вашего сайта</p>
          <div class="item-page__copy-script mb-11" id="formScript">
            &lt;script&gt; var es_forms_params = {"form_tenant_secret": "{{ integration.form_params.form_tenant_secret }}",
            "secret":
            "{{ integration.form_params.form_secret }}"}; &lt;/script&gt;
            <br>
            &lt;script id="evi_sales_forms_script" async charset="utf-8"
            src="{{ integration.form_params.script_url }}"
            &gt;&lt;/script&gt;
          </div>
        </div>
        <div class="d-flex flex-column mt-5">
          <h4 class="mb-11">Дублировать сообщение на почту:</h4>
          <v-text-field
              class="evi-text-field mb-11 form-name"
              color="#44D370"
              v-model="email"
              :disabled="!hasRight('integrations.manage')"
              @change="save"
              label="Email"
              :rules="emailRules"
          >
          </v-text-field>
        </div>
        <div class="d-flex flex-column mt-5">
          <h4 class="mb-11">Настройки формы:</h4>
          <v-text-field
              class="evi-text-field mb-11 form-name"
              color="#44D370"
              label="Название формы"
              v-model="formName"
              :disabled="!hasRight('integrations.manage')"
              @change="save"
          >
          </v-text-field>
          <v-text-field
              v-for="(i, index) in fields"
              :key="index"
              class="evi-text-field mb-11"
              color="#44D370"
              v-model="i.placeholder"
              :disabled="!hasRight('integrations.manage')"
              @change="save"
          >
            <template v-slot:label>
              Название поля
            </template>
            <template v-slot:append>
              <v-icon size="18" color="#A7AFB8" v-if="showTrash(i.name) && hasRight('integrations.manage')" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
              <v-icon v-if="showTrash(i.name) && hasRight('integrations.manage')" @click="removeField(index)" class="pointer" color="#A7AFB8">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-text-field>
          <div class="add-field pointer mb-11" @click="createFieldModal = true" v-if="hasRight('integrations.manage')">
            <v-icon>mdi-plus</v-icon>
            Добавить поле
          </div>
          <v-textarea
              class="evi-textarea"
              color="#44D370"
              v-model="message"
              no-resize
              @change="save"
              :disabled="!hasRight('integrations.manage')"
          >
            <template v-slot:label>
              Сообщение после заполнения формы
            </template>
          </v-textarea>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="removeModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление интеграции: "{{ integration.name }}"
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить интеграцию?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="remove"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="createFieldModal"
        max-width="612px"
    >
      <CreateField @createField="createField" @close="closeCreateModal" is-form-fields/>
    </v-dialog>
    <v-dialog
        v-model="editFieldModal"
        max-width="612px"
    >
      <CreateField @editField="editField" :selectedField="selectedField" @close="closeEditModal" is-form-fields/>
    </v-dialog>
  </div>
</template>

<script>
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {hasRight} from '@/utils/access/hasRight';
import {mapActions, mapState} from "vuex";
import {translit} from "../../tools/Helpers";
import CreateField from "../../components/clients/CreateField.vue";

export default {
  name: "IntegrationPage",
  components: {CreateField},
  mixins: [UserAutocompleteRequestsMixin],
  data: () => ({
    removeModal: false,
    selectedUsers: [],
    selectedTags: [],
    formName: '',
    email: '',
    fields: [],
    message: '',
    editFieldModal: false,
    createFieldModal: false,
    selectedField: {},
  }),
  computed: {
    ...mapState("integrations", ['integration',]),
    ...mapState("common", ["loading"]),
    ...mapState("tags", ['tags',]),
    localUsers() {
      let array = [...this.selectedUsers];
      this.users.forEach(i => {
        if (!this.selectedUsers.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
    emailRules() {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
    localTags() {
      let array = [...this.selectedTags];
      this.tags.forEach(i => {
        if (!this.selectedTags.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
  },
  methods: {
    ...mapActions({
      getIntegration: 'integrations/getIntegration',
      getIntegrationForm: 'integrations/getIntegration',
      deleteIntegrationForm: 'integrations/deleteIntegration',
      editIntegrationResponsibleUsers: 'integrations/editIntegrationResponsibleUsers',
      editIntegrationForms: 'integrations/editIntegrationForms',
      editIntegrationTags: 'integrations/editIntegrationTags',
      getTags: 'tags/getItemsBy',
    }),
    hasRight: hasRight,
    editField(val) {
      const field = this.fields.find(i => i.name === val.name);
      if (val.values) {
        field.values = val.values;
      }
      field.name = val.name;
      field.type = val.type;

      this.save();
    },
    createField(val) {
      const field = {
        placeholder: val.name,
        name: translit(val.name),
        type: val.type,
      };

      if (val.values) {
        field.values = val.values;
      }

      this.fields.push(field);
      this.save();
    },
    openEditFieldModal (item) {
      this.selectedField = item;
      this.editFieldModal = true;
    },
    closeEditModal () {
      this.selectedField = null;
      this.editFieldModal = false;
    },
    closeCreateModal () {
      this.createFieldModal = false;
    },
    copyScript () {
      let copyText = document.getElementById("formScript");

      let copyTextarea = document.createElement("textarea");
      copyTextarea.style.position = "fixed";
      copyTextarea.style.opacity = "0";
      copyTextarea.textContent = copyText.textContent;

      document.body.appendChild(copyTextarea);
      copyTextarea.select();
      document.execCommand("copy");
      document.body.removeChild(copyTextarea);
    },
    getData() {
      let id = this.$route.params.id;
      this.getIntegrationForm(id);
    },
    saveUsers() {
      this.editIntegrationResponsibleUsers(
          {
            id: this.integration.id,
            selectedUsers: this.selectedUsers,
          }
      );
    },
    saveTags () {
      this.editIntegrationTags(
          {
            id: this.integration.id,
            selectedTags: this.selectedTags.map(i => i.id),
          }
      );
    },
    save() {
      this.fields.forEach(i => {
        if (i.name === 'new_field' || (
            i.name !== 'name' && i.name !== 'email' && i.name !== 'phone'
        )) {
          i.name = translit(i.placeholder)
        }
        i.label = i.placeholder;
      });

      let payload = {
        name: this.formName,
        message: this.message,
        fields: this.fields,
        email: this.email || null,
        id: this.integration.id,
      };

      this.editIntegrationForms(payload);
    },
    remove() {
      this.deleteIntegrationForm(this.integration).then(() => {
        this.removeModal = false;
        this.$router.push({path: '/integrations/type/form'});
      });
    },
    showTrash(v) {
      return v !== 'name' && v !== 'email' && v !== 'phone';
    },
    removeField(i) {
      this.fields.splice(i, 1);
      this.save();
    }
  },
  watch: {
    integration(val) {
      if (val.form_params) {
        this.selectedUsers = val.users ? val.users : [];
        this.selectedTags = val.tags ? val.tags : [];
        this.fields = val.form_params.fields ? val.form_params.fields : [];
        this.message = val.form_params.message ? val.form_params.message : '';
        this.formName = val.name ? val.name : '';
        this.email = val.form_params.email ? val.form_params.email : '';
      }
    },
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.evi-autocomplete {
  max-width: 361px;
}
</style>
